import React from 'react';
import { Link } from 'react-router-dom';
// import blogs from '../../api/blogs';

// import { Link } from 'react-router-dom'
import projMan from '../../images/projSub.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog-details/author.jpg'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'


// const ClickHandler = () => {
//     window.scrollTo(10, 0);
// }

const ProjMan = (props) => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    const submitHandler = (e) => {
        e.preventDefault()
    }

  return (
    <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-9 col-12 ${props.blRight}`}>
                        <div className="blog-content">
                            

                            <div className="tag-share">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={projMan} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="">FFSD GROUP</Link></li>
                                    <li><Link to="">24th January, 2024.</Link></li>
                                    <li><Link to="">Application Deadline: 29 January 2025</Link></li>
                                </ul>
                                <h2>Job Title: Construction Project Manager <br /> Location: Asaba, Delta State, Nigeria. <br/> Contract Duration: 6 Months to 1 Year.</h2>
                                <h3>Job Description</h3>
                                <p>We are seeking an experienced and results-driven Construction Project Manager to oversee and manage a high-profile construction project in Asaba. The successful candidate will be responsible for planning, coordinating, and executing all phases of the project to ensure it is delivered on time, within budget, and to the highest quality standards.  </p>
                                <p>If you have a proven track record in construction project management and thrive in a fast-paced environment, we would love to hear from you!  </p>
                                
                                <h3>Key Responsibilities</h3>
                                <p>
                                    - Plan, execute, and monitor all stages of the construction project from initiation to completion. <br/>
                                    - Coordinate with architects, engineers, contractors, and other stakeholders to ensure project milestones are met. <br />
                                    - Develop and maintain project schedules, budgets, and work plans. <br/> 
                                    - Manage on-site teams and ensure compliance with health and safety regulations. <br/>
                                    - Oversee procurement of materials and resources, ensuring timely delivery. <br/>
                                    - Conduct regular inspections to monitor progress and address issues proactively. <br/>
                                    - Prepare and present progress reports to stakeholders and management.
                                </p>

                                <h3>Qualifications and Skills</h3>
                                <p>
                                    - Bachelor’s degree in Civil Engineering, Construction Management, or related field.  <br/>
                                    - Minimum of 3 years of experience as a Project Manager in the construction industry. <br/> 
                                    - Strong knowledge of project management principles, tools, and software (e.g., Microsoft Project, AutoCAD).  <br/>
                                    - Excellent leadership, communication, and organizational skills.  <br/>
                                    - Proficient in budgeting and cost control.  <br/>
                                    - Strong problem-solving skills and ability to work under pressure.  <br/>
                                    - Familiarity with local building codes and construction standards.
                                </p>

                                <h3>What We Offer</h3>
                                <p>
                                    - Competitive salary.  
                                    - Opportunity to work on a high-profile project with an established company.  
                                    - Professional growth and development opportunities.
                                </p>

                                <h3>How to Apply</h3>
                                <p>
                                    Interested candidates should submit their CV and cover letter detailing their experience and suitability for the role to our e-mail address, <a href="mailto:careers@ffsdgroup.com"> careers@ffsdgroup.com </a>
                                </p>
                            </div>
                            {/* <h4>Location: Asaba, Delta State, Nigeria. <br/> Contract Duration: 6 Months to 1 Year.</h4> */}
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ProjMan