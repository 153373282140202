import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';    
import Footer from '../../components/footer/Footer';
import Logo from '../../images/ffsdgroup.png';
import ProjMan from '../../components/ProjMan/ProjMan';


const CareerPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Career'} pagesub={'About'} />
            <ProjMan blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CareerPage;
